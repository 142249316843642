.App {
  text-align: center;
  padding: 50px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

button {
  cursor: pointer;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.instructions {
  text-align: left;
}

.section {
  padding: 50px;
}

.section--followup-prompt {
  padding-bottom: 0px;
}

.prompt-input {
  width: 100%;
  height: 100px;
  margin-top: 20px;
}

.submit-button {
  border: none;
  color: white;
  background-color: darkred;
  padding: 10px;
  border-radius: 5px;
  width: 200px;
}

.progress {
  color: white;
  border-radius: 5px;
  height: 50px;
  border: none;
  background-color: rgb(38, 166, 224);
  padding: 15px;
}

.download-link:visited,
.download-link:hover,
.download-link:active,
.download-link:link {
  display: block;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  height: 50px;
  background-color: rgb(99, 218, 175);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  margin: 0 auto;
}

.options {
  width: 95%;
  margin-bottom: 20px;
}

.outline {
  outline: 3px solid black;
  margin: 50px;
}